.icons-prompts-page {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-right: 0.3rem;
  margin-bottom: 1.5rem;
}

.prompt-title-view {
  font-size: 1.4rem;
}

.button-back {
  margin-top: 3rem;
}

.comment-box {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 2rem 2.5rem;
  gap: 0.7rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.comment-author-date {
  font-size: 0.75rem;
  color: #828282;
}

.simple-link {
  text-decoration: underline;
}

@media only screen and (max-width: 992px) {
  .container-prompt {
    width: 90%;
    margin: 0 auto;
  }
}
